import React, {FC}  from 'react';
import {observer}   from 'mobx-react-lite';
import Dropdown     from 'react-bootstrap/cjs/Dropdown';

export enum ArtefactFilter {
  All = "All Artefacts",
  WithArtefacts = "With Artefacts",
  WithoutArtefacts = "Without Artefacts"
}

interface PropsType {
    currentBuildsArtefactFilter  : ArtefactFilter;
    updateBuildsArtefactFilter   : (artefactsFilter: ArtefactFilter) => void;
    disabled           : boolean;
}

const BuildArtefactsFilterSelector : FC<PropsType> = observer(({currentBuildsArtefactFilter, updateBuildsArtefactFilter, disabled }) => {

    return (
        <Dropdown drop="up">
          {disabled
            ? <Dropdown.Toggle id="dropdown-basic" disabled>
              N/A
            </Dropdown.Toggle>
            : <Dropdown.Toggle id="dropdown-basic">
              {currentBuildsArtefactFilter}
            </Dropdown.Toggle>
          }

            <Dropdown.Menu>
              {(Object.keys(ArtefactFilter) as Array<keyof typeof ArtefactFilter>).map(key => {
                return <Dropdown.Item key={key} onClick={() => updateBuildsArtefactFilter(ArtefactFilter[key])}>
                  {ArtefactFilter[key]}
                </Dropdown.Item>
              })
              }
            </Dropdown.Menu>
        </Dropdown>
    )
})

export default BuildArtefactsFilterSelector
