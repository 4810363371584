import React, {FC}      from 'react';
import {Routes, Route}  from "react-router-dom";
import { BuildsIndex } from './builds';
import {joinRoute, matchDeeply} from "@yakoffice/shared-components";
import {BuildsRouteItems} from "../routes";

export const ProjectsIndex : FC = () => {

  return <Routes>
    <Route path={matchDeeply(joinRoute(BuildsRouteItems.ProjectIdParam,BuildsRouteItems.Builds))} element={<BuildsIndex />}/>
  </Routes>
}

export default ProjectsIndex;

