import styles         from './buildsTable.module.sass';
import React, {FC}    from 'react';
import {observer}     from 'mobx-react-lite';
import {useNavigate, useParams} from 'react-router-dom';
import {BuildModel}   from '../../models/buildModel';
import * as routes    from '../../routes/routes';
import { BuildIcon }  from '../components/buildIcon';


interface PropsType {
    builds: BuildModel[];
}


const BuildsTable : FC<PropsType> = observer((props) => {

    const routeParams   = useParams<{projectId: string, buildId: string}>()
    const navigate       = useNavigate()

    const linkToBuild = (buildId: string) => {
        navigate(routes.link_BUILD(routeParams.projectId, buildId))
    }

    return (
        <div>
            {props.builds.map(build => {
                return (
                    <div key={build.id} className={styles.buildEntry} onClick={() => linkToBuild(build.id)}>
                        <div className={styles.groupOne}>
                            <BuildIcon type={build.type} />
                            <div className={styles.buildId}>
                                {build.id} <span> {build.triggeredBy}</span>
                                <small className="text-capitalize text-muted">
                                    Triggered by: {build.triggeredByUsername}
                                </small>
                            </div>
                        </div>
                        <div className={styles.groupTwo}>
                            <div className={styles.buildDate}>
                                {build.createdAt.seconds}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
})

export default BuildsTable;
