import styles                       from './build.module.sass';
import React, {FC}                  from 'react';
import {observer}                   from 'mobx-react-lite';
import Container                    from 'react-bootstrap/cjs/Container';
import Row                          from 'react-bootstrap/cjs/Row';
import Col                          from 'react-bootstrap/cjs/Col';
import {CustomSpinner}              from '@yakoffice/custom-spinner';
import BuildNav                     from '../components/buildNav';
import {useBuild}                   from '../../loaders/use-build';
import ArtefactsAccordion           from '../artefacts/artefactsAccordion';
import {useParams} from "react-router-dom";


const Build : FC = observer(() => {

  const {buildId}         = useParams<{buildId: string}>();
  const buildResult       = useBuild(buildId);

  return buildResult.isLoaded
    ? buildResult.build
      ? <div>
        <BuildNav build={buildResult.build}/>
        <Container>
          <Row>
            <Col>
              <div className={styles.buildDataBox}>
                <div className={styles.buildHeading}>Build Type:</div>
                <div className={styles.buildText}>{buildResult.build.triggeredBy}</div>
              </div>
            </Col>
            <Col>
              <div className={styles.buildDataBox}>
                <div className={styles.buildHeading}>Triggered By:</div>
                <div className={styles.buildText}>{buildResult.build.triggeredByUsername}</div>
              </div>
            </Col>
          </Row>
        </Container>
        {buildResult.build.artefacts.length > 0 &&
          <ArtefactsAccordion build={buildResult.build}/>
        }
      </div>
      : <div/>
    : <CustomSpinner spinnerText={`Loading build ${buildId}...`}/>
})

export default Build;
