export function getDeviceOS(): string {

    const userAgent = navigator.userAgent;

    if (/windows phone/i.test(userAgent) || /android/i.test(userAgent)) {
        return "android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return "ios";
    }

    return "all";
}
