import {useEffect, useState}    from 'react';
import {useParams}              from 'react-router-dom';
import {NotificationType,
    usePublishNotification}     from '@yakoffice/notification-handler';
import {BuildsStore}            from '../models/buildsStore';
import {BuildModel}             from '../models/buildModel';

interface buildControllerResult{
  isLoaded    : boolean;
  build      : BuildModel | null
}

export const useBuild = (buildId: string) => {

  const publishNotification = usePublishNotification()
  const {projectId} = useParams<{ projectId: string }>();

  const [result, setResult] = useState<buildControllerResult>(() => ({
    isLoaded: false,
    build: null
  }));

  useEffect(() => {

    const buildsStore = new BuildsStore();
    buildsStore.getBuild(projectId, parseInt(buildId))
      .then(builds => {
        setResult({isLoaded: true, build: builds.length > 0 ? builds[0] : null});
      })
      .catch(e => {
        console.log(e);
        publishNotification({
          title: "Error Loading Build",
          notificationType: NotificationType.Error,
          message: e.toString()
        });
        setResult({isLoaded: true, build: null})
      })

  }, [projectId, buildId, publishNotification]);

  return result;
};
