import React, { FC }  from 'react';
import styles         from './buildIcon.module.sass'

interface PropTypes{
  type: string,
  className? : string
}

export const BuildIcon : FC<PropTypes> = ({type, className}) => {
  return <div className={`${styles.buildIcon} ${type} ${className ?? ""}`}>
    <i className="fas fa-tools" />
  </div>
}
