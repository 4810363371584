
export enum BuildsRouteItems {
  Projects = "projects",
  ProjectIdParam =":projectId",
  Builds = "builds",
  BuildIdParam = ":buildId"
}

export function link_BUILDS (projectId: string) {
    return `/projects/${projectId}/builds`;
}

export function link_BUILD (projectId: string, buildId: string) {
    return `/projects/${projectId}/builds/${buildId}`;
}
