import React, {FC}              from 'react';
import {observer}               from 'mobx-react-lite';
import {useNavigate, useParams} from 'react-router-dom';
import Navbar                   from 'react-bootstrap/cjs/Navbar';
import type {IBuild}                 from '../../models/interfaces/IBuild';
import * as routes              from '../../routes/routes';
import styles                   from './buildNav.module.sass'
import { BuildIcon }            from './buildIcon';
import Container                from 'react-bootstrap/cjs/Container';


interface PropsType {
    build: IBuild;
}


const BuildNav : FC<PropsType> = observer((props) => {

    const navigate       = useNavigate()
    const routeParams   = useParams<{projectId: string}>();

    return (
        <Navbar variant="dark" className={styles.navbar}>
          <Container>
            <div className={styles.back} onClick={() => {navigate(routes.link_BUILDS(routeParams.projectId))}}><i className="fas fa-arrow-left" /></div>
            <Navbar.Brand>
                <BuildIcon type={props.build.type} className="me-2" />
                {props.build.id}
            </Navbar.Brand>
            <div className={styles.buildDate}>
                {props.build.createdAt.seconds}
            </div>
          </Container>
        </Navbar>
    )
})
export default BuildNav;
