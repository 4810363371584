import React, {FC}  from 'react';
import {observer}   from 'mobx-react-lite';
import Dropdown     from 'react-bootstrap/cjs/Dropdown';


interface PropsType {
    currentBuildsLimit  : number;
    updateBuildsLimit   : (newBuildsLimit: number) => void;
    disabled            : boolean;
}


const BuildsLimitSelector: FC<PropsType> = observer((props) => {

    return (
        <Dropdown drop="up">
            {props.disabled
                ?   <Dropdown.Toggle id="dropdown-basic" disabled>
                        N/A
                    </Dropdown.Toggle>
                :   <Dropdown.Toggle id="dropdown-basic">
                        {props.currentBuildsLimit}
                    </Dropdown.Toggle>
            }
            <Dropdown.Menu>
                <Dropdown.Item onClick={() => props.updateBuildsLimit(10)}>10</Dropdown.Item>
                <Dropdown.Item onClick={() => props.updateBuildsLimit(25)}>25</Dropdown.Item>
                <Dropdown.Item onClick={() => props.updateBuildsLimit(50)}>50</Dropdown.Item>
                <Dropdown.Item onClick={() => props.updateBuildsLimit(100)}>100</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
})

export default BuildsLimitSelector
