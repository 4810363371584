import React, {FC}  from 'react';
import {observer}   from 'mobx-react-lite';
import Dropdown     from 'react-bootstrap/cjs/Dropdown';
import {useAuthUser} from '@yakoffice/yakoffice-firebase';

export enum BuildTypeFilter {
  All = "all",
  Adhoc = "adhoc",
  Intraday = "intraday",
  Nightly = "nightly",
  Playtest = "playtest",
  Production = "production",
  Testing = "testing"
}

interface PropsType {
    currentBuildTypeFilter  : BuildTypeFilter;
    updateBuildTypeFilter   : (newBuildsLimit: BuildTypeFilter) => void;
    disabled           : boolean;
}

const getNameForFilter = (type: BuildTypeFilter) => {
  switch(type) {
    case BuildTypeFilter.Adhoc: return "Adhoc"
    case BuildTypeFilter.Intraday: return "Intraday"
    case BuildTypeFilter.Nightly: return "Nightly"
    case BuildTypeFilter.Playtest: return "Playtest"
    case BuildTypeFilter.Production: return "Production"
    case BuildTypeFilter.Testing: return "Testing"
    default: return "All Types"
  }
}

const BuildTypeFilterSelector: FC<PropsType> = observer((props) => {

    return (
        <Dropdown drop="up">
            {props.disabled
             ?   <Dropdown.Toggle id="dropdown-basic" disabled>
                 N/A
             </Dropdown.Toggle>
             :   <Dropdown.Toggle id="dropdown-basic">
                 {getNameForFilter(props.currentBuildTypeFilter)}
             </Dropdown.Toggle>
            }

            <Dropdown.Menu>
              {(Object.keys(BuildTypeFilter) as Array<keyof typeof BuildTypeFilter>).map(key => {
                return <Dropdown.Item key={key} onClick={() => props.updateBuildTypeFilter(BuildTypeFilter[key])}>
                  {getNameForFilter(BuildTypeFilter[key])}
                </Dropdown.Item>
              })
              }
            </Dropdown.Menu>
        </Dropdown>
    )
})

export default BuildTypeFilterSelector
