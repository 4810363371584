import React, {FC, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import Button from 'react-bootstrap/cjs/Button';
import {CustomSpinner} from '@yakoffice/custom-spinner';
import {useBuilds} from '../../loaders/use-builds';
import BuildsLimitSelector from '../components/buildsLimitSelector';
import BuildTypeFilterSelector, {BuildTypeFilter} from '../components/buildTypeFilterSelector';
import BuildIdSearchInput from '../components/buildIdSearchInput';
import BuildsNav from '../components/buildsNav';
import BuildsTable from './buildsTable';
import Alert from 'react-bootstrap/cjs/Alert';
import styles from './builds.module.sass';
import Container from 'react-bootstrap/cjs/Container';
import BuildArtefactsFilterSelector, {ArtefactFilter} from "../components/buildArtefactsFilterSelector";
import {BuildModel} from "../../models/buildModel";
import BuildsByUsernameFilterSelector from '../components/buildsByUsernameFilterSelector';
import {user} from 'firebase-functions/lib/providers/auth';
import {useAuthUser} from '@yakoffice/yakoffice-firebase';

const BuildsArtefactsStorageKey = 'buildsArtefacts';
const BuildsLimitsStorageKey = 'buildsLimit';
const BuildsTypesStorageKey = 'buildsType';

interface IBuildsState {
  buildIdSearchVal?: number;
  buildIdSearchVisibility?: boolean;
  buildsLimit: number;
  buildsType: BuildTypeFilter;
  buildsByUsername: string;
}

const Builds: FC = observer(() => {

  const [buildsState, setBuildsState] = useState<IBuildsState>(() => {
    const parsedLimit = parseInt(window.localStorage.getItem(BuildsLimitsStorageKey));
    return {
      buildIdSearchVal: null,
      buildIdSearchVisibility: false,
      buildsLimit: isNaN(parsedLimit) ? 10 : parsedLimit,
      buildsType: window.localStorage.getItem(BuildsTypesStorageKey) as BuildTypeFilter ?? BuildTypeFilter.All,
      buildsByUsername: null
    }
  });
  const [buildsArtefactsFilter, setBuildsArtefactsFilter] = useState(window.localStorage.getItem(BuildsArtefactsStorageKey) as ArtefactFilter ?? ArtefactFilter.All);
  const buildsResult = useBuilds({ buildsLimit: buildsState.buildsLimit, buildsType: buildsState.buildsType, buildId: buildsState.buildIdSearchVal, buildsByUsername: buildsState.buildsByUsername });
  const authUser = useAuthUser();
  const username = authUser.firebaseUser.displayName.toLowerCase().substr(0, authUser.firebaseUser.displayName.indexOf(" "));

  useEffect(() => {
    window.localStorage.setItem(BuildsLimitsStorageKey, buildsState.buildsLimit.toString());
  }, [buildsState.buildsLimit]);

  useEffect(() => {
    window.localStorage.setItem(BuildsTypesStorageKey, buildsState.buildsType);
  }, [buildsState.buildsType]);

  useEffect(() => {
    window.localStorage.setItem(BuildsArtefactsStorageKey, buildsArtefactsFilter);
  }, [buildsArtefactsFilter]);

  const toggleBuildsLimit = (newLimit: number) => {
    setBuildsState(
      {
        buildsLimit: newLimit,
        buildsType:  buildsState.buildsType,
        buildsByUsername: buildsState.buildsByUsername
      }
    );
  };

  const updateBuildsType = (newType: BuildTypeFilter) => {
    setBuildsState(
      {
        buildsLimit: buildsState.buildsLimit,
        buildsType:  newType,
        buildsByUsername: buildsState.buildsByUsername
      }
    );
  };

  const updateBuildsArtefactsFilter = (artefactsFilter: ArtefactFilter) => setBuildsArtefactsFilter(artefactsFilter);

  const updateBuildsByUsername = () => {

    setBuildsState({
                      buildsLimit: buildsState.buildsLimit,
                      buildsType: buildsState.buildsType,
                      buildsByUsername: buildsState.buildsByUsername === "" ? username : ""
                   })
  }

  const searchOnBuildId = (buildId: number) => {
    setBuildsState(
      {
        buildIdSearchVal: buildId,
        buildsLimit:      buildsState.buildsLimit,
        buildsType:       buildsState.buildsType,
        buildsByUsername: buildsState.buildsByUsername
      }
    );
  };

  const toggleSearchVisibility = () => {
    setBuildsState(
      {
        buildIdSearchVal:        buildsState.buildIdSearchVal,
        buildIdSearchVisibility: !buildsState.buildIdSearchVisibility,
        buildsLimit:             buildsState.buildsLimit,
        buildsType:              buildsState.buildsType,
        buildsByUsername: buildsState.buildsByUsername
      }
    );
  };

  const filterArtefacts = (build: BuildModel) => {
    if(buildsArtefactsFilter === ArtefactFilter.WithArtefacts)
      return build.artefacts?.length > 0;

    if(buildsArtefactsFilter === ArtefactFilter.WithoutArtefacts)
      return build.artefacts == null || build.artefacts.length === 0;

    return true;
  }

  const filteredForArtefacts = buildsResult.builds.filter(b => filterArtefacts(b));

  return (
    <React.Fragment>
      <BuildsNav toggleSearchVisibility={toggleSearchVisibility} buildIdSearchVisibility={buildsState.buildIdSearchVisibility} />

      {buildsState.buildIdSearchVisibility &&
       <BuildIdSearchInput currentBuildId={buildsState.buildIdSearchVal} searchOnBuildId={searchOnBuildId} />
      }

      {buildsResult.isLoaded
       ? filteredForArtefacts.length > 0
          ? <BuildsTable builds={filteredForArtefacts} />
          : <Container><Alert variant='warning'>No builds Found</Alert></Container>
       : <CustomSpinner spinnerText='Loading builds...' />
      }

      <div className={styles.footer}>
        {buildsState.buildIdSearchVal
         ? <div className='clear-search'><Button onClick={() => searchOnBuildId(null)} variant='primary'><i className='fas fa-arrow-left' /></Button></div>
         : <React.Fragment>
           <BuildsLimitSelector currentBuildsLimit={buildsState.buildsLimit} updateBuildsLimit={toggleBuildsLimit} disabled={!!buildsState.buildIdSearchVal} />
           <BuildTypeFilterSelector currentBuildTypeFilter={buildsState.buildsType} updateBuildTypeFilter={updateBuildsType} disabled={!!buildsState.buildIdSearchVal || !!buildsState.buildsByUsername} />
           <BuildsByUsernameFilterSelector disabled={false} updateBuildsByUsername={updateBuildsByUsername} isActive={!!buildsState.buildsByUsername} />
           <BuildArtefactsFilterSelector currentBuildsArtefactFilter={buildsArtefactsFilter} updateBuildsArtefactFilter={updateBuildsArtefactsFilter} disabled={!!buildsState.buildIdSearchVal} />
         </React.Fragment>
        }
      </div>
    </React.Fragment>
  );
});

export default Builds;
