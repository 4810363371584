import React, {FC}              from 'react';
import {observer}               from 'mobx-react-lite';
import Navbar                   from 'react-bootstrap/cjs/Navbar';
import Button                   from 'react-bootstrap/cjs/Button';
import Container                from 'react-bootstrap/cjs/Container';
import { ProjectLogo }          from '@yakoffice/shared-components';
import styles                   from './buildsNav.module.sass';
import { useParams }            from 'react-router-dom';
import ppkLogo from '../../images/ppk.svg'; //todo will need to be swapped for image file generated from project
import dofLogo from '../../images/dof.svg'; //todo will need to be swapped for image file generated from project
import yaoLogo from '../../images/YakOfficeMobile.svg'; //todo will need to be swapped for image file generated from project

interface PropsType {
    buildIdSearchVisibility : boolean;
    toggleSearchVisibility  : () => void;
}


const BuildsNav : FC<PropsType> = observer((props) => {


  const {projectId} = useParams<{projectId: string}>()

  const logo = () => {
    switch(projectId) {
      case "ppk":
        return ppkLogo;
      case "dof":
        return dofLogo;
      default:
        return yaoLogo;
    }
  }

  return (
      <Navbar variant="dark" className={styles.navbar}>
          <Container>
              <Navbar.Brand>
                <a href="https://portal.yakoffice.app/projects" className={styles.brandLink}>
                  <ProjectLogo src={logo()} alt="" className={styles.projectLogo} /> Builds
                </a>
              </Navbar.Brand>
              <Button variant="link" className="me-0 ms-auto" onClick={() => {props.toggleSearchVisibility()}}>
                  {!props.buildIdSearchVisibility ? <i className="fas fa-search" /> : <i className="fas fa-times" />}
              </Button>
          </Container>
      </Navbar>
  )
})
export default BuildsNav;
