import React, {FC, useState} from 'react';
import {observer}   from 'mobx-react-lite';
import Dropdown     from 'react-bootstrap/cjs/Dropdown';
import {useAuthUser} from '@yakoffice/yakoffice-firebase';
import {ArtefactFilter} from './buildArtefactsFilterSelector';
import {Button} from 'react-bootstrap';

interface PropsType {
  disabled: boolean;
  isActive: boolean;
  updateBuildsByUsername: () => void;
}

const BuildsByUsernameFilterSelector: FC<PropsType> = observer((props) => {

  return (
      props.disabled
       ?   <Button id="dropdown-basic" style={{borderRadius: 0, flexGrow: 1, height: "100%"}} disabled>N/A</Button>
       :   <Button id="dropdown-basic" style={{borderRadius: 0, flexGrow: 1, height: "100%"}} className={props.isActive ? "btn-warning" : ""} onClick={() => props.updateBuildsByUsername()}>{props.isActive ? "MY BUILDS" : "ALL BUILDS"}</Button>

  )
})

export default BuildsByUsernameFilterSelector
