import React, {FC, useState}    from 'react';
import {observer}               from 'mobx-react-lite';
import Form                     from 'react-bootstrap/cjs/Form';
import Button                   from 'react-bootstrap/cjs/Button';
import {InputGroup}             from 'react-bootstrap';
import styles                   from './buildIdSearchInput.module.sass';

interface PropsType {
    currentBuildId  : number;
    searchOnBuildId : (buildId: number) => void;
}

interface BuildIdSearchInputState {
    buildId: null | number;
}


const BuildIdSearchInput: FC<PropsType> = observer((props) => {

    const [BuildIdSearchInputState, setBuildId] = useState(() => ({
        buildId: null,
    } as  BuildIdSearchInputState));


    const updateBuildId = (buildId: number) => {
        setBuildId(
            {
                buildId: buildId,
           }
       )
    }

    return (
            <Form onSubmit={(e) => {e.preventDefault(); props.searchOnBuildId(BuildIdSearchInputState.buildId)}} className={styles.searchForm}>
                <InputGroup className="mb-3">
                    <Form.Control
                        type="number"
                        placeholder={props.currentBuildId ? JSON.stringify(props.currentBuildId) : "Enter build id"}
                        defaultValue={props.currentBuildId && props.currentBuildId}
                        onChange={(e) => {updateBuildId(parseInt(e.target.value))}}
                    />
                    <Button variant="success" type="submit"><i className="fas fa-search" /></Button>
                </InputGroup>
            </Form>
    )
})

export default BuildIdSearchInput
