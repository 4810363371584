import styles           from './artefactsAccordion.module.sass';
import React, {FC}      from 'react';
import {useParams}      from 'react-router-dom';
import {observer}       from 'mobx-react-lite';
import { Accordion }    from 'react-bootstrap';
import type {IBuild}         from '../../models/interfaces/IBuild';
import {Platform}       from '../../models/enums/platform';
import {getDeviceOS}    from '../../utils';
import Card             from 'react-bootstrap/cjs/Card';
import {useYakofficeUserIdToken} from "@yakoffice/yakoffice-firebase";


interface PropsType {
    build   : IBuild;
}


const ArtefactsAccordion : FC<PropsType> = observer((props) => {

    const routeParams   = useParams<{projectId: string, buildId: string}>();
    const os            = getDeviceOS();
    const token = useYakofficeUserIdToken()

    const artefacts = () => {
        if(os === "ios")
            return props.build.sortedArtefacts.ios;
        else if(os === "android")
            return props.build.sortedArtefacts.android;
        else
            return props.build.sortedArtefacts.allArtefacts;
    }

    const accordionBody = (artefact) => {
      return (
        <div className={styles.accordionBody}>

          {/**** PLATFORM SPECIFIC ****/}
          <dl key={artefact.id}>
            <dt>ID</dt>
            <dd>{artefact.id}</dd>

            <dt>Application Name</dt>
            <dd>{artefact.applicationName}</dd>

            {artefact.platform === "android" &&
             <div>
               <dt>Architecture</dt>
               <dd>{artefact.architecture}</dd>
             </div>
            }

            <dt>Build</dt>
            <dd>
              <ul>
                <li>Config: {artefact.build.config}</li>
                <li>ID: {artefact.build.id}</li>
              </ul>
            </dd>

            <dt>Bundle Id</dt>
            <dd>{artefact.bundleId}</dd>

            <dt>Commit Sha</dt>
            <dd>{artefact.commitSha}</dd>

            <dt>Environment</dt>
            <dd>{artefact.env}</dd>
            {artefact.platform === "android" &&
             <div>
               <dt>Market</dt>
               <dd>{artefact.market}</dd>

               <dt>SDK Version</dt>
               <dd>{artefact.sdkVersion}</dd>

               <dt>Target SDK Version</dt>
               <dd>{artefact.targetSdkVersion}</dd>
             </div>
            }

            {artefact.platform === "ios" &&
             <div>
               <dt>Target OS Version</dt>
               <dd>{artefact.targetOsVersion}</dd>
             </div>
            }

            <dt>Timestamp</dt>
            <dd>{artefact.timestamp.seconds}</dd>

            <dt>Unity</dt>
            <dd>
              <ul>
                <li>Scripting backend: {artefact.unity.scriptingBackend}</li>
                <li>Version: {artefact.unity.version}</li>
              </ul>
            </dd>

            <dt>Version</dt>
            <dd>{artefact.version}</dd>

            {artefact.platform === "android" &&
             <div>
               <dt>Version Code</dt>
               <dd>{artefact.versionCode}</dd>
             </div>
            }
          </dl>
        </div>
      )
    }

    return (
        <div>
            {artefacts().map(artefact => {

                const downloadLink = artefact.downloadUrl(os, routeParams.projectId, token);
                const artefactIconClass = (artefact.platform === Platform.android) ? `fa-android` : `fa-apple`;

                return (
                  <Accordion key={artefact.id} className={styles.artifactAccordion}>
                    <Accordion.Item eventKey={artefact.id}>
                      <Card.Header style={{"padding": "5px 5px", "marginBottom": "5px"}}>
                        <Accordion.Header className={styles.accordionToggle}>
                          <div className={styles.accordionTitleText}>
                            <i className={`fab ${styles.deviceIcon} ${artefactIconClass} ${artefact.platform}`} />
                            {artefact.build.config} <small className={artefact.env}>({artefact.env})</small>
                          </div>
                        </Accordion.Header>
                        <div className={styles.accordionTitleLink}>
                          <a className='btn btn-primary' href={downloadLink}><i className="fas fa-cloud-download-alt" /></a>
                          <small className="d-block">{artefact.size}</small>
                        </div>
                      </Card.Header>
                      <Accordion.Body>
                        {accordionBody(artefact)}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                )
            })}
        </div>
    )
})

export default ArtefactsAccordion;
