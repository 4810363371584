import { useEffect, useState } from 'react';
import { bearerTokenProvider } from '../../utils';

export const useYakofficeUserIdToken = (forceRefresh = false) =>
{
  const [token, setToken] = useState<string>();

  // NB.  This will be called every time so beware of infinite re-rendering
  useEffect(() => {
    bearerTokenProvider
      .getToken(forceRefresh)
      .then(newToken => {
        if(newToken !== token)
          setToken(newToken)
      });
  });

  return token;
}
