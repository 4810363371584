export function dynamicSort(property: string) {

    let sortOrder = 1;

    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
        console.log(property);
    }

    return function (a: unknown, b: unknown) {

        const result = (a[property].parseInt < b[property].parseInt) ? -1 : (a[property].parseInt > b[property].parseInt) ? 1 : 0;

        return result * sortOrder;
    }
}
