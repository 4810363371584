export function compareBuildsById(a, b) {
    const buildA = parseInt(a._id);
    const buildB = parseInt(b._id);

    let comparison = 0;
    if (buildA < buildB) {
        comparison = 1;
    } else if (buildA > buildB) {
        comparison = -1;
    }
    return comparison;
}