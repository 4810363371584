import React                            from 'react';
import { createRoot } from 'react-dom/client';
import {App}                              from './views/app';
import { BrowserRouter } from 'react-router-dom';
import { NotificationHandler }          from '@yakoffice/notification-handler';
import { ModalHandler }                 from '@yakoffice/custom-modal';
import './index.sass';
import {WithAuthentication, WithAuthorisation} from "@yakoffice/with-authorisation";
import {initYakofficeFirebaseApp} from "@yakoffice/yakoffice-firebase";

initYakofficeFirebaseApp();

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('root')!)
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <NotificationHandler>
        <ModalHandler>
          <WithAuthentication>
            <WithAuthorisation toolId="builds">
              <App />
            </WithAuthorisation>
          </WithAuthentication>
        </ModalHandler>
      </NotificationHandler>
    </BrowserRouter>
  </React.StrictMode>
);

