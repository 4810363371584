import {action, observable}         from 'mobx';
import moment                       from 'moment/moment';
import {Platform}                   from './enums/platform';
import {Environment}                from './enums/environment';
import {formatBytes}                from '../utils';
import type {IBuildArtefactBuild}        from './interfaces/artefact/IBuildArtefactBuild';
import type {IBuildArtefactUnity}        from './interfaces/artefact/IBuildArtefactUnity';
import type {IBuildArtefact}             from './interfaces/artefact/IBuildArtefact';
import type {IBuildArtefactAndroid}      from './interfaces/artefact/IBuildArtefactAndroid';
import type {IBuildArtefactIOS}          from './interfaces/artefact/IBuildArtefactIOS';
import type {IBuildArtefactTimestamp}    from './interfaces/artefact/IBuildArtefactTimestamp';
import {QueryDocumentSnapshot, getFirestore, onSnapshot, doc} from 'firebase/firestore';
import type {DocumentData} from 'firebase/firestore';
import {builds_api_url} from "../config/build-api-url";

export abstract class BuildArtefactModel implements IBuildArtefact{

    public static create(qds: QueryDocumentSnapshot, buildId: string): BuildArtefactModel {

        const platform = qds.data().platform as Platform;

        switch (platform) {

            case Platform.android:
                return new AndroidArtefact(qds, buildId);

            case Platform.ios:
                return new BuildArtefactIOSModel(qds, buildId);

            default:
                throw new Error(`Unrecognised platform: ${platform}`);
        }
    }

    private  readonly _buildId              : string;
    private _cancellationToken              : Action;
    private readonly _id                    : string;
    @observable private _applicationName    : string;
    @observable private _build              : IBuildArtefactBuild;
    @observable private _bundleId           : string;
    @observable private _downloadUrl        : string;
    @observable private _downloadUrlIosD    : string;
    @observable private _commitSha          : string;
    @observable private _env                : Environment;
    @observable private _platform           : Platform;
    @observable private _size               : number;
    @observable private _timestamp          : IBuildArtefactTimestamp;
    @observable private _unity              : IBuildArtefactUnity;
    @observable private _version            : string;

    protected constructor(qds: QueryDocumentSnapshot, buildId: string) {

        this._id            = qds.id;
        this._buildId       = buildId;
        this.onUpdateReceived(qds.data());

        const db                = getFirestore();
        this._cancellationToken = onSnapshot(doc(db, qds.ref.path), (ds) => {

                                                         if (ds.exists)
                                                             this.onUpdateReceived(ds.data())
                                                     },
                                                     e => console.error(`Error in Artefact snapshot listener: ${e}`));
    }

    public get applicationName(): string {
        return this._applicationName;
    }

    public get build(): IBuildArtefactBuild {
        return this._build;
    }

    public get bundleId(): string {
        return this._bundleId;
    }

    public get commitSha(): string {
        return this._commitSha;
    }

    public downloadUrl(os: string, projectId: string, token: string) {

      if (os === "all" && this.platform === "ios")
        this._downloadUrl = this.createDownloadUrl(projectId, token);
      else if (this._platform === Platform.android)
        this._downloadUrl = this.createDownloadUrl(projectId, token);

      else if (this._platform === Platform.ios) {
        const and = '%26';
        const eq = '%3D';
        const qm = '%3F';

        this._downloadUrl = `itms-services://?action=download-manifest&url=${builds_api_url}/ios/manifest${qm}p${eq}${projectId}${and}b${eq}${this._buildId}${and}a${eq}${this._id}${and}t${eq}${token}`;
      }

      return this._downloadUrl;
    }

    private createDownloadUrl(projectId: string, token: string): string {
        return `${builds_api_url}/download?p=${projectId}&b=${this._buildId}&a=${this._id}&t=${token}`;
    }

    public get env(): Environment {
        return Environment[this._env];
    }

    public get id(): string {
        return this._id;
    }

    public get platform(): Platform {
        return this._platform;
    }

    public get size(): string {
        return formatBytes(this._size, 3);
    }

    public get timestamp(): IBuildArtefactTimestamp {
        const t = new Date(0);
        return  {
            seconds: parseInt(moment(t.setSeconds(this._timestamp.seconds)).format('DD/MM/YY HH:mm')),
            nanoseconds: parseInt(moment(t.setSeconds(this._timestamp.nanoseconds)).format('DD/MM/YY HH:mm'))
        };
    }

    public get unity(): IBuildArtefactUnity {
        return this._unity;
    }

    public get version(): string {
        return this._version;
    }

    protected onUpdateReceived(data: DocumentData) {
        this._applicationName   = data.applicationName;
        this._build             = data.build;
        this._bundleId          = data.bundleId;
        this._commitSha         = data.commitSha;
        this._env               = data.env;
        this._platform          = data.platform;
        this._size              = data.size;
        this._timestamp         = data.timestamp;
        this._unity             = data.unity;
        this._version           = data.version;
    }
}

class AndroidArtefact extends BuildArtefactModel implements IBuildArtefactAndroid{

    @observable private _architecture       : string;
    @observable private _market             : string;
    @observable private _sdkVersion         : string;
    @observable private _targetSdkVersion   : string;
    @observable private _versionCode        : string;

    protected onUpdateReceived = action((data: DocumentData) => {
        super.onUpdateReceived(data);

        this._architecture      = data.android.architecture;
        this._market            = data.android.market;
        this._sdkVersion        = data.android.sdkVersion;
        this._targetSdkVersion  = data.android.targetSdkVersion;
        this._versionCode       = data.android.versionCode;
    })

    public get architecture(): string {
        return this._architecture;
    }

    public get market(): string {
        return this._market;
    }

    public get sdkVersion(): string {
        return this._sdkVersion;
    }

    public get targetSdkVersion(): string {
        return this._targetSdkVersion;
    }

    public get versionCode(): string {
        return this._versionCode;
    }
}

class BuildArtefactIOSModel extends BuildArtefactModel implements IBuildArtefactIOS{

    @observable private _targetOsVersion: string;

    protected onUpdateReceived = action((data: DocumentData) => {
        super.onUpdateReceived(data);

        this._targetOsVersion = data.ios.targetOsVersion;
    })

    public get targetOsVersion(): string {
        return this._targetOsVersion;
    }
}
