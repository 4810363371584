import {action, observable, runInAction} from 'mobx';
import moment               from 'moment/moment';
import {BuildArtefactModel} from './buildArtefactModel';
import {dynamicSort}        from '../utils';
import type {IBuild}             from './interfaces/IBuild';
import type {IBuildCreatedAt}    from './interfaces/IBuildCreatedAt';
import type {IBuildArtefact}     from './interfaces/artefact/IBuildArtefact';
import type {DocumentData } from 'firebase/firestore';
import { collection, getFirestore, onSnapshot, QueryDocumentSnapshot, doc } from 'firebase/firestore';
import {ISortedArtefacts} from "./interfaces/ISortedArtefacts";

export class BuildModel implements IBuild {

    private readonly _cancellationToken         : Action;
    private readonly _id                        : string;
    @observable private readonly _artefacts     : BuildArtefactModel[];
    @observable private _commitSha              : string;
    @observable private _createdAt              : IBuildCreatedAt;
    @observable private _triggeredBy            : string;
    @observable private _triggeredByUsername    : string;
    @observable private _type                   : string;


    constructor(qds: QueryDocumentSnapshot) {

      this._artefacts = observable([]);
      this._id        = qds.id;

      this.onUpdateReceived(qds.data());

      const db              = getFirestore();
      const artefactsToken  = onSnapshot(collection(db, `${qds.ref.path}/artefacts`), (qs) => {

        qs.docChanges().forEach(change => {
          if (change.type === 'added')
            this.onArtefactAdded(change.doc);
        });
      });

      const buildToken = onSnapshot(doc(db, qds.ref.path), (ds) => this.onUpdateReceived(ds.data()),
          e => console.error(`Error in Build snapshot listener: ${e}`));

        this._cancellationToken = () => {
            artefactsToken();
            buildToken();
        };
    }

    public stopListening() {
        this._cancellationToken();
    }

    public get artefacts(): BuildArtefactModel[] {
        return this._artefacts; //map to vm here i guess
    }

    public get sortedArtefacts(): ISortedArtefacts {

        const artefacts = this._artefacts.slice();

        artefacts.sort(dynamicSort('platform'));

        const android         : IBuildArtefact[] = [];
        const ios             : IBuildArtefact[] = [];

        artefacts.forEach((artefact) => {
            if(artefact.platform === "android")
                android.push(artefact);
            else
                ios.push(artefact);
        });

        ios.sort( this.compareEnv );
        ios.sort( this.compareConfig );

        android.sort( this.compareEnv );
        android.sort( this.compareConfig );

        const allArtefacts = ios.concat(android);

        return {
            android,
            ios,
            allArtefacts
        };
    }

    public get commitSha(): string {
        return this._commitSha;
    }

    public get createdAt(): IBuildCreatedAt {
        const t = new Date(0);
        return  {
            seconds: moment(t.setSeconds(parseInt(this._createdAt.seconds))).format('DD/MM/YY HH:mm'),
            nanoseconds: moment(t.setSeconds(parseInt(this._createdAt.nanoseconds))).format('DD/MM/YY HH:mm')
        };
    }

    public get id(): string {
        return this._id;
    }

    public get triggeredBy(): string {
        const regex         = /Snapshot dependency; .+?;.+\/ ?(.+?)(?:'|,|$)/;
        const nameRef = this._triggeredBy.match(regex);

        if(nameRef)
            return nameRef[1];
        else
            return this._triggeredBy;
    }

    public get triggeredByUsername(): string {
        if(this._triggeredByUsername === "n/a")
            return "Cron";
        else
            return this._triggeredByUsername;
    }

    public get type(): string {
      if (this._type) {
        return runInAction(() => this._type.replace(/'/g, ''));
      } else {
        return this._type;
      }
    }

    private onArtefactAdded = action((qds: QueryDocumentSnapshot) => {
        this._artefacts.push(BuildArtefactModel.create(qds, this._id));
    })

    private onUpdateReceived = action((data: DocumentData) => {

        this._commitSha             = data.commitSha;
        this._createdAt             = data.createdAt;
        this._triggeredBy           = data.triggeredBy;
        this._triggeredByUsername   = data.triggeredByUsername;
        this._type                  = data.type;
    })

    private compareEnv(a: IBuildArtefact, b: IBuildArtefact) {
        if ( a.env < b.env ){
            return -1;
        }
        if ( a.env > b.env ){
            return 1;
        }
        return 0;
    }

    private compareConfig(a: IBuildArtefact, b: IBuildArtefact) {
        if ( a.build.config < b.build.config ){
            return -1;
        }
        if ( a.build.config > b.build.config ){
            return 1;
        }
        return 0;
    }
}


